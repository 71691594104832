import react from 'react';
import { Image } from '@chakra-ui/react'

function Hero() {


    return (

        <div className="h-100 vw-100 px-5 py-4 py-md-5">
            <div className="px-5">
                <div className="container-fluid px-5 ">
                    <div className=" row px-5">
                        <div className="col-12  col-lg-7 col-md-9 pt-5    mb-lg-0">
                            <div className=" pt-md-4 pt-0  ">
                                <span style={{ fontSize: "64px", zIndex: "2", color: "#0D3458", fontWeight: "600", lineHeight: "70.08px" }}>
                                    The
                                    <Image src='/image/REAL.png'

                                        className="real"

                                    />
                                    <Image src='/image/garis-REAL.png'

                                        className="garis-real"

                                    />
                                    <br></br>
                                    Estate Agent</span>
                                <span className="text-end mb-2 mb-md-0" style={{ fontSize: "24px", color: "#0D3458", fontWeight: "500", lineHeight: "32.16px", }}><br></br>
                                    Solusi Jual, Beli dan Sewa Properti
                                </span>



                            </div>

                            <div className="col-12  mt-4 position-relative h-25 " style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "36px" }}>
                                <div className="position-absolute bottom-0 d-none d-md-block">
                                    Saya Ingin...
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-5  mt-md-3 mt-lg-0 pe-5 d-none d-md-flex  ">

                            <Image src='/image/Depan-1.png'
                                height="414px"
                                className="gambar-depan"
                            />
                        </div>
                        <div className="col-12 pt-md-4 pt-lg-5 mt-0 mt-md-5  d-none d-md-block">
                            <div className="row">
                                <div className="col-md mb-2">
                                    <div className=" card shadow-lg px-4 py-4 "
                                        style={{ height: "280px" }}
                                    >
                                        <div className="d-flex justify-content-center mx-4 my-4">

                                            <Image src='/image/house-1.png'
                                                height="66px"
                                                width="66px"

                                            />
                                        </div>
                                        <span className="text-center mb-3" style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "26.4px" }}>
                                            Jual Properti
                                        </span>
                                        <span className="" style={{ fontSize: "15px", color: "#1E396C", fontWeight: "400", lineHeight: "20.48px" }}>
                                            Kami akan membantu Anda untuk memasarkan properti yang Anda miliki .

                                        </span>
                                    </div>
                                </div>
                                <div className="col-md mb-2">
                                    <div className=" card shadow-lg px-4 py-4 "
                                        style={{ height: "280px" }}
                                    >
                                        <div className="d-flex justify-content-center mx-4 my-4">
                                            <Image src='/image/house-2.png'
                                                height="66px"
                                                width="66px"

                                            />
                                        </div>
                                        <span className="text-center mb-3" style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "26.4px" }}>
                                            Beli Properti
                                        </span>
                                        <span className="" style={{ fontSize: "15px", color: "#1E396C", fontWeight: "400", lineHeight: "20.48px" }}>
                                            Cek rekomendasi properti terbaik dari kami serta informasi terlengkapnya.

                                        </span>
                                    </div>
                                </div>
                                <div className="col-md mb-2">
                                    <div className=" card shadow-lg px-4 py-4 "
                                        style={{ height: "280px" }}
                                    >
                                        <div className="d-flex justify-content-center mx-4 my-4">
                                            <Image src='/image/house-3.png'
                                                height="66px"
                                                width="66px"

                                            />
                                        </div>
                                        <span className="text-center mb-3" style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "26.4px" }}>
                                            Sewa Properti
                                        </span>
                                        <span className="" style={{ fontSize: "15px", color: "#1E396C", fontWeight: "400", lineHeight: "20.48px" }}>
                                            Pilihan Properti untuk Anda yang menginginkan sewa properti terbaik.

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-md-4 pt-lg-5 mt-0 mt-md-5 px-md-5 px-0  d-block d-md-none">
                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-md mb-2">
                                        <div className=" card shadow-lg ">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                                        <div className="d-flex justify-content-center ">
                                                            <Image src='/image/house-1.png'


                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-9 py-2">
                                                        <span className="text-center mb-3" style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "26.4px" }}>
                                                            Beli Properti
                                                        </span>
                                                        <span className="d-block d-lg-none" style={{ fontSize: "15px", color: "#1E396C", fontWeight: "400", lineHeight: "20.48px" }}>
                                                            Cek rekomendasi properti terbaik dari kami serta informasi terlengkapnya.

                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md mb-2">
                                        <div className=" card shadow-lg ">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                                        <div className="d-flex justify-content-center ">
                                                            <Image src='/image/house-2.png'


                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-9 py-2">
                                                        <span className="text-center mb-3" style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "26.4px" }}>
                                                            Jual Properti
                                                        </span>
                                                        <span className="d-block d-lg-none" style={{ fontSize: "15px", color: "#1E396C", fontWeight: "400", lineHeight: "20.48px" }}>
                                                            Kami akan membantu Anda untuk memasarkan properti yang Anda miliki .
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md mb-2">
                                        <div className=" card shadow-lg ">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                                        <div className="d-flex justify-content-center ">
                                                            <Image src='/image/house-3.png'


                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-9 py-2">
                                                        <span className="text-center mb-3" style={{ fontSize: "24px", color: "#1E396C", fontWeight: "700", lineHeight: "26.4px" }}>
                                                            Sewa Properti
                                                        </span>
                                                        <span className="d-block d-lg-none" style={{ fontSize: "15px", color: "#1E396C", fontWeight: "400", lineHeight: "20.48px" }}>
                                                            Pilihan Properti untuk Anda yang menginginkan sewa properti terbaik.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

};

export default Hero;