import react from 'react';
import { Image } from '@chakra-ui/react'
import { AiOutlineArrowRight } from 'react-icons/ai'

import { useNavigate } from 'react-router-dom';

import {
    BsInstagram,
    BsFacebook
} from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';


function Footerlanding() {

    const navigate = useNavigate();
    return (

        <div className="h-100 vw-100 bg-blue px-5">
            
            <div className="container-fluid px-5">
                <div className="row py-5 px-5 mx-5">
                    <div className="col-12 mb-5">

                        <Image src='/image/logo.png'
                            width="251px"
                            height="67px"
                        />
                    </div>
                    <div className="col-6 text-light">



                        <span
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "700",
                                lineHeight: "30px",
                                letterSpacing: "0px",
                                textAlign: "left",
                            }}
                        >
                            Jakarta
                        </span>
                        <div className="row mt-2">

                            <div className="col-1 d-flex justify-content-end pe-0 pt-1">
                                <AiOutlineArrowRight />
                            </div>

                            <div className="col-10">
                                Property Gallery, Mal Ambasador, 4th Floor #31, Jl. Prof. Doktor Satrio Karet Kuningan, Setiabudi Jakarta Selatan, DKI Jakarta – 12940
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-1 d-flex justify-content-end pe-0 pt-1">
                                <AiOutlineArrowRight />
                            </div>
                            <div className="col-10">
                                (021) 5760621
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-1 text-end d-flex justify-content-end pe-0 pt-1">
                                <AiOutlineArrowRight />
                            </div>
                            <div className="col-10">
                                info@casablancarealty.co.id
                            </div>
                        </div>
                        <div className="mt-5">
                            <span
                                style={{

                                    fontSize: "22px",
                                    fontWeight: "400",
                                    lineHeight: "33px",
                                    letterSpacing: "0px",
                                    textAlign: "left"

                                }}>
                                Temukan kami di media sosial
                            </span>
                            <div className="row mt-1">
                                <div className="col-1"><BsInstagram className="fs-2" /></div>
                                <div className="col-1"><BsFacebook className="fs-2" /></div>
                                <div className="col-1"><FaTiktok className="fs-2" /></div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 text-light">
                        <span
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "700",
                                lineHeight: "30px",
                                letterSpacing: "0px",
                                textAlign: "left",
                            }}
                        >
                            Bali
                        </span>

                        <div className="row mt-2">

                            <div className="col-1 d-flex justify-content-end pe-0 pt-1">
                                <AiOutlineArrowRight />
                            </div>

                            <div className="col-10">
                                Jimbaran Arcade Unit M, Jl. Uluwatu I No.177 Jimbaran, Kuta Selatan, Kabupaten Badung, Bali – 80361
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-1 d-flex justify-content-end pe-0 pt-1">
                                <AiOutlineArrowRight />
                            </div>
                            <div className="col-10">
                                (0361) 4468515
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-1 d-flex justify-content-end pe-0 pt-1">
                                <AiOutlineArrowRight />
                            </div>
                            <div className="col-10">
                                info@casablancarealty.co.id
                            </div>
                        </div>

                        <div className="col-12 mt-5 pt-4">


                            <div className="d-flex justify-content-end pt-3">
                                <div onClick={() => navigate("/")} className="btn fw-bold btn-blue text-light">
                                    Beranda
                                </div>
                                <div onClick={() => navigate("/Properti")} className="btn fw-bold btn-blue text-light">
                                    Properti
                                </div>
                                <div onClick={() => navigate("/tentangkami")} className="btn fw-bold btn-blue ms-2 text-light">
                                    Tentang Kami
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default Footerlanding;