import React from "react";

function CarouselOrange() {

    return (
        <div style={{
           
            height:"424px",
            background:"#F26A2A"
        }}>
            test
           aaaaaaaaaa
        </div>
    );
}

export default CarouselOrange;