import React from "react";
import { Image } from "@chakra-ui/react";
function Blog() {
    return (
        <div className="col-4 px-2 py-4 ">
        <Image
            style={{
                borderRadius: "10px",
                height: "182px"
            }}
            src="/image/Apartmen1.png"
            className="w-100"
        />
        <div className="col-12 pt-2">
            <div className="row">
                <div className="col-1">
                    <div style={{

                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "34px",
                        letterSpacing: "0px",
                        textAlign: "left"

                    }}
                        className="position-absolute">

                        32
                    </div>

                </div>
                <div className="col-1 ms-2">
                    <Image src="/image/wide.png"
                        width="30px"
                        height="31px"
                        position="absolute"
                    />
                </div>
                <div className="col-1">
                    <div style={{

                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "34px",
                        letterSpacing: "0px",
                        textAlign: "left"

                    }}
                        className="position-absolute ms-3">

                        32
                    </div>

                </div>
                <div className="col-1 ms-4">
                    <Image src="/image/bed.png"
                        width="30px"
                        height="31px"
                        position="absolute"
                    />
                </div>
                <div className="col-1">
                    <div style={{

                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "34px",
                        letterSpacing: "0px",
                        textAlign: "left"

                    }}
                        className="position-absolute ms-3">

                        32
                    </div>

                </div>
                <div className="col-2 ms-4">
                    <Image src="/image/bath.png"
                        width="30px"
                        height="31px"
                        position="absolute"
                    />
                </div>
                &nbsp;
            </div>
        </div>

        <div className="col-12 pt-2"
            style={{

                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "32px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "#1E396C"
            }}
        >
            Kuningan 3BR Luxury Apartment by Shila
        </div>
        <div className="col-8 px-3 py-1"
            style={
                {
                    backgroundColor: "#F26A2A",
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#FFF"
                }
            }
        >
           Rp.1.600.000.000
        </div>
    </div>
    );
}

export default Blog