import React from "react";
import { Image } from "@chakra-ui/react";
function HalamanTentang() {

    return (
        <div className="h-100 px-5 pt-5">
            <div className="px-5 mx-5 pt-5">
                <div className="px-5">
                    <div className=" pt-md-4 pt-0   ">
                        <span style={{ fontSize: "60px", zIndex: "2", color: "#0D3458", fontWeight: "900", lineHeight: "69.75px" }}>
                            The
                            <Image src='/image/REAL.png'
                                className="real"
                            />
                            <Image src='/image/garis-REAL.png'
                                className="garis-real"
                            />
                            <br></br>
                            Estate Agent</span>
                    </div>

                    <div className="col-12 py-5">
                        <span style={{
                            fontSize: "28px",
                            fontWeight: "700",
                            lineHeight: "42px",
                            letterSpacing: "0px",
                            textAlign: "left",
                            color: "#1E396C"
                        }}>
                            Casablanca Realty
                        </span>
                    </div>

                    <div className="col-12 mb-5">
                        <span
                            style={{

                                fontSize: "20px",
                                fontWeight: "400",
                                lineHeight: "30px",
                                letterSpacing: "0px",
                                textAlign: "left"

                            }}
                        >
                            Didirikan sejak tahun 1998 oleh dua orang pakar properti yang memiliki latar belakang kuat di bidang keuangan real estate, dan industri investasi.
                            Casablanca Realty menjadi one stop Real Estate Agency yang memberikan pelayanan profesional kepada setiap konsumennya.
                            <br></br>
                            <br></br>
                            Berfokus di Jakarta Pusat dan Selatan, di kawasan CBD seperti Kuningan, Menteng, atau Sudirman, Casablanca Realty akan terus membantu menemukan
                            kebutuhan properti Anda, seperti: rumah, apartemen, ruang kantor, dan lainnya.
                        </span>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default HalamanTentang