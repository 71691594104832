import React from "react";
import { Image } from "@chakra-ui/react";
function FacilityProperti() {

    return (
        <div className=" vh-100 px-5 mx-5 pt-5">
            
            <div className="px-5 mx-5 pt-4">
                <div className="row">
                    <div className="col-4 pt-2">
                        <div className="col-12 px-2 py-2">


                            <div className="card px-2 py-2 mb-4">
                                <div className="row">
                                    <div className="col-3">
                                        <Image

                                            src="/image/wide.png"
                                            width="41.72px"
                                            height="41.72px"
                                        />
                                    </div>
                                    <div className="col-9"
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                            lineHeight: "36px",
                                            color: "#1E396C"
                                        }}>
                                        <span className="fw-bolder"> 64</span> sqm
                                    </div>
                                </div>
                            </div>

                            <div className="card px-2 py-2 mb-4">
                                <div className="row">
                                    <div className="col-3">
                                        <Image

                                            src="/image/bed.png"
                                            width="41.72px"
                                            height="41.72px"
                                        />
                                    </div>
                                    <div className="col-9"
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                            lineHeight: "36px",
                                            color: "#1E396C"
                                        }}>
                                        <span className="fw-bolder"> 2</span> Bedroom
                                    </div>
                                </div>
                            </div>

                            <div className="card px-2 py-2 mb-4">
                                <div className="row">
                                    <div className="col-3">
                                        <Image

                                            src="/image/bath.png"
                                      
                                            width="41.72px"
                                            height="41.72px"
                                        />
                                    </div>
                                    <div className="col-9"
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                            lineHeight: "36px",
                                            color: "#1E396C"
                                        }}>
                                        <span className="fw-bolder"> 2</span> Bathroom
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <span
                            style={{

                                fontSize: "28px",
                                fontWeight: "700",
                                lineHeight: "42px",
                                letterSpacing: "0px",



                            }}
                        >
                            Facility & Services
                        </span>

                        <ul style={{
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: "400",
                            lineHeight: "36px",
                            letterSpacing: "0px",
                            textAlign: "left"

                        }}>
                            <li>
                                Ut felis facilisis et gravida posuere egestas eget cras. Lectus eget.
                            </li>
                            <li>
                                Eros amet adipiscing integer viverra. Eget nunc aliquam tortor.
                            </li>
                            <li>
                                Eros amet adipiscing integer viverra. Eget nunc aliquam tortor.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="mt-2 mb-2"
                        style={{
                            fontFamily: "Poppins",
                            fontSize: "28px",
                            fontWeight: "700",
                            lineHeight: "42px",
                            letterSpacing: "0px",
                            textAlign: "left",
                            color: "#1E396C"

                        }}
                    >
                        Surrounding Areas
                    </div>

                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        2.3
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        &nbsp;KM&nbsp;
                                    </span>

                                    Hospital

                                </div>
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        2.3
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        &nbsp;KM&nbsp;
                                    </span>

                                    Hospital

                                </div>
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        2.3
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        &nbsp;KM&nbsp;
                                    </span>

                                    Hospital

                                </div>
                            </div>
                            <div className="col-6">
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        2.3
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        &nbsp;KM&nbsp;
                                    </span>

                                    Hospital

                                </div>
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        2.3
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        &nbsp;KM&nbsp;
                                    </span>

                                    Hospital

                                </div>
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left"
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        2.3
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            lineHeight: "45px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#F26A2A"
                                        }}
                                    >
                                        &nbsp;KM&nbsp;
                                    </span>

                                    Hospital

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

export default FacilityProperti;