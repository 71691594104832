import react from 'react';

import Header from '../Component/Header';
import Promotion from '../Component/Promotion';
import Footerlanding from '../Component/Footerlanding';
import HeroProperti from '../Component/Properti/HeroProperti';
import FacilityProperti from '../Component/Properti/FacilityProperti';
import CarouselOrange from '../Component/CarouselOrange';
function Properti() {

    return (


        <>
            <Header />
            <HeroProperti />
            <FacilityProperti />
            <CarouselOrange />
            <Promotion />

            <Footerlanding />
        </>

    );
}
export default Properti;
