import react from 'react';
import { Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
function Header() {

    const navigate = useNavigate();

    return (

        <div className="px-0 text-light">
            
            <header className="d-block bg-blue px-0 px-md-5 py-2 " >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-9 col-md-5">
                            <div onClick={()=> navigate("/")} className="justify-content-start d-flex cursor-pointer">
                                <Image src='/image/logo.png'/>
                                
                            </div>
                        </div>
                        <div className="col-3  col-md-7 justify-content-end pt-2   d-none d-md-flex">
                            <div onClick={()=>navigate("/Properti")} className="btn fw-bold btn-blue text-light">
                                Properti
                            </div>
                            <div onClick={()=> navigate("/tentangkami")} className="btn fw-bold btn-blue ms-2 text-light">
                                Tentang Kami
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );

};

export default Header;