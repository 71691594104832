import React from "react";
import { Image } from "@chakra-ui/react";
function DividerCity() {
    return (
        <div className="vw-100 user-select-none"
            style={{

                height: "268px",
                borderRadius: "5px"

            }}
        >
            <Image src="/image/pexels-yentl-jacobs.png"
            />
        </div>

    );
}

export default DividerCity