import React from "react";
import Header from "../Component/Header";
import Footerlanding from "../Component/Footerlanding";
import Promotion from "../Component/Promotion";
import HalamanBeli from "../Component/Beli/HalamanBeli";
function BeliProperti() {

    return (
        <>
            <Header />
            <HalamanBeli />
            <div className="row d-flex justify-content-center">
                <div>
                
                </div>
            </div>
            <div className="col-12 d-flex justify-content-center mt-5 px-0 py-0 garisbawah">
                <div
                    style={{
                        width: "904.0000000000034px",
                        border: "3px",
                        height: "3px",
                        fontSize: "3px",
                        backgroundColor: "#F26A2A"
                    }}
                >
                </div>
            </div>

            Tempat BreadCrumb
            <Promotion />
            <Footerlanding />


        </>
    );
}

export default BeliProperti