import React from "react";
import Header from "../Component/Header";
import Footerlanding from "../Component/Footerlanding";
import Sponsor from "../Component/Homepage/Sponsor";
import Motto from "../Component/Motto";
import DividerCity from "../Component/DividerCity";
import HalamanTentang from "../Component/TentangKami/HalamanTentang";
import Solusi from "../Component/TentangKami/Solusi";

function TentangKami() {
    return(
        <>
        <Header/>
        <HalamanTentang/>
        <Solusi/>
        <DividerCity/>
        <Motto/>
        <Sponsor/>
        <Footerlanding/>
        </>
    );
}

export default TentangKami