import React from "react";
import { Image } from "@chakra-ui/react";
function Solusi() {

    return (
        <div className="px-5">
            <div className="px-5">
                <div className="px-5 mx-5">
                    <span style=
                        {{
                            color: "#1E396C",

                            fontSize: "28px",
                            fontWeight: "700",
                            lineHeight: "42px",
                            letterSpacing: "0px",
                            textAlign: "left",

                        }}>

                        <span style={{ color: "#F26A2A" }}>
                            Casablanca Realty
                            &nbsp;
                        </span>
                        akan membantu Anda dalam menemukan solusi terbaik untuk kebutuhan properti Anda :
                    </span>

                </div>
                <div className="row mt-5 mb-5">

                    <div className="col-6 px-2 py-2">
                        <div className=" rad-7px shadow-md"
                            style={{
                                height: "199px",
                                width: "555px"
                            }}
                        >
                            <div className="row px-4 py-4">
                                <div className="col-3 ">
                                <Image
                                    src="/image/expectation.png"
                                    />
                                </div>
                                <div className="col-9">
                                    <span className="judul-biru-20px">Mengidentifikasi kebutuhan Klien.</span>
                                    <br></br>
                                    <span className="isi-biru-14px">
                                        Mengidentifikasi dan menilai informasi dan data rinci tentang kebutuhan klien, baik untuk menjual, mencari properti, atau menyewa.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 px-2 py-2">
                        <div className=" rad-7px shadow-md"
                            style={{
                                height: "199px",
                                width: "555px"
                            }}
                        >
                            <div className="row px-4 py-4">
                                <div className="col-3 ">
                                <Image
                                    src="/image/town.png"
                                    />
                                </div>
                                <div className="col-9">
                                    <span className="judul-biru-20px">
                                        Menemukan properti terbaik
                                    </span>
                                    <br></br>
                                    <span className="isi-biru-14px">
                                        Membantu menemukan properti dengan membuat ulasan berdasarkan: Harga, Jenis properti, dan Aspek hukum
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4 d-flex justify-content-center">
                        <div className=" rad-7px shadow-md"
                            style={{
                                height: "199px",
                                width: "555px"
                            }}
                        >
                            <div className="row px-4 py-4">
                                <div className="col-3 ">
                                <Image
                                    src="/image/research.png"
                                    />
                                </div>
                                <div className="col-9">
                                    <span className="judul-biru-20px">
                                        Melakukan survey ke lokasi
                                    </span>
                                    <br></br>
                                    <span className="isi-biru-14px">
                                        Team kami akan menemani Anda saat mendatangi lokasi properti yang Anda inginkan.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 px-2 py-2">
                        <div className=" rad-7px shadow-md"
                            style={{
                                height: "199px",
                                width: "555px"
                            }}
                        >
                            <div className="row px-4 py-4">
                                <div className="col-3 ">
                                <Image
                                    src="/image/rent.png"
                                    />
                                </div>
                                <div className="col-9">
                                    <span className="judul-biru-20px">
                                        Mempersiapkan dokumen legalitas
                                    </span>
                                    <br></br>
                                    <span className="isi-biru-14px">
                                        Bekerjasama dengan notaris dan konsultan pajak, kami akan membantu Anda untuk mempersiapkan dokumen legalitas yang diperlukan
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 px-2 py-2">
                        <div className=" rad-7px shadow-md"
                            style={{
                                height: "199px",
                                width: "555px"
                            }}
                        >
                            <div className="row px-4 py-4">
                                <div className="col-3 ">
                                    <Image
                                    src="/image/salesafter.png"
                                    />
                                </div>
                                <div className="col-9">
                                    <span className="judul-biru-20px">
                                        Sales After Service
                                    </span>
                                    <br></br>
                                    <span className="isi-biru-14px">
                                        Membantu Anda untuk mengatasi masalah properti Anda, bahkan saat Anda sudah menempatinya
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Solusi