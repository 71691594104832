import React from "react";
import { Image } from "@chakra-ui/react";
function Motto() {

    return (
        <div className="px-5 mx-5 py-5">
            <span style=
                {{
                    fontSize: "28px",
                    fontWeight: "700",
                    lineHeight: "42px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    color: "#F26A2A"
                }}
            >
                Motto Kami
            </span>

            <div className="row mt-4">

                <div className="col-3 ">
                    <div className="shadow-md rad-5px pt-5"
                        style={{
                            width: "266px",
                            height: "271px"

                        }}
                    >
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <Image
                                src="/image/caring.png"
                                height="105px"
                                width="104px"
                            />
                        </div>
                        <div>
                            <div className="col-12 h-50 d-flex justify-content-center"
                                style={{


                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "36px",
                                    letterSpacing: "0px",
                                    textAlign: "left"


                                }}
                            > Caring
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-3 ">
                    <div className="shadow-md rad-5px pt-5"
                        style={{
                            width: "266px",
                            height: "271px"

                        }}
                    >
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <Image
                                src="/image/eco.png"
                                height="105px"
                                width="104px"
                            />
                        </div>
                        <div>
                            <div className="col-12 h-50 d-flex justify-content-center"
                                style={{


                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "36px",
                                    letterSpacing: "0px",
                                    textAlign: "left"


                                }}
                            > Nurture
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-3 ">
                    <div className="shadow-md rad-5px pt-5"
                        style={{
                            width: "266px",
                            height: "271px"

                        }}
                    >
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <Image
                                src="/image/businessman.png"
                                height="105px"
                                width="104px"
                            />
                        </div>
                        <div>
                            <div className="col-12 h-50 d-flex justify-content-center"
                                style={{


                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "36px",
                                    letterSpacing: "0px",
                                    textAlign: "left"


                                }}
                            > Profesionalism
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-3  position-relative">
                    <div className="shadow-md rad-5px pt-5"
                        style={{
                            width: "266px",
                            height: "271px"

                        }}
                    >
                        <div className="col-12 mt-4 d-flex justify-content-center">
                            <Image
                                src="/image/shield.png"
                                height="104px"
                                width="105px"
                                style={{
                                    

                                }}
                            />
                        </div>
                        <div>
                            <div className="col-12 d-flex justify-content-center"
                                style={{


                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "36px",
                                    letterSpacing: "0px",
                                    textAlign: "left"


                                }}
                            > Protect
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Motto