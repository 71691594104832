import react from 'react';
import { Image } from '@chakra-ui/react'
function Sponsor() {

    return (
        <div className="px-4 px-md-5 bg-orange py-4 h-100">
            <div className="px-md-5 px-0 mx-0 mx-md-5">
                <div className="px-5">
                    <span style={{
                        fontFamily: "Poppins",
                        fontSize: "32px",
                        fontWeight: "700",
                        lineHeight: "48px",
                        letterSpacing: "0px",
                        textAlign: "left",
                        color: "#fff"
                    }}>
                        Partner Kami
                    </span>
                </div>
                <div className="px-5 mt-3">
                    <div className="card px-3 py-2 mb-4">
                        <Image src='/image/Grouppartner.png'
                            width="100%"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sponsor;