import react from 'react';

function Adminpage() {


    return(

        <>
        </>
    );

};

export default Adminpage;