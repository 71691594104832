import React from "react";

function HeroProperti() {
    return (
        <div className="BG-Apartmen1">
            <div className="px-5 mx-5 pt-5 vh-100 ">
                <div className="px-5 pt-4">
                    <div style={{
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "36px",
                        color: "#F26A2A"
                    }}>
                        Jenis Apartment (Low/Rise Apartment)
                    </div>
                    <div className="col-7"
                        style={{
                            fontSize: "50px",
                            fontWeight: "900",
                            lineHeight: "60px",
                            color: "#1E396C"
                        }}>
                        Judul, Nama dan Merk Apartmen
                    </div>
                    <div
                        style={{
                            fontSize: "32px",
                            fontWeight: "600",
                            lineHeight: "48px",
                            color: "#F26A2A"
                        }}
                    >
                        Lokasi
                    </div>
                    Image with mini image


                </div>

            </div>
        </div>
    );
}

export default HeroProperti