import React from "react";
import { Image } from "@chakra-ui/react";

import Blog from "../blog";

function HalamanBeli() {
    return (
        <div className="px-5 mx-5 pt-5 h-100 ">
            <div className="px-5 pt-4"
            >
                <div className="col-12 text-center"
                    style={{

                        fontSize: "32px",
                        fontWeight: "700",
                        lineHeight: "39px",
                        letterSpacing: "0px",
                        textAlign: "left"


                    }}
                >
                    <span
                        style={{
                            color: "#1E396C"
                        }}
                    >
                        Beli
                    </span>
                    <span
                        style={{
                            color: "#F26A2A"
                        }}
                    >
                        Properti
                    </span>
                </div>
                <div className="row pt-5 ">


                    <Blog/>
                    <Blog/>
                    <Blog/>
                    <Blog/>
                    <Blog/>
                    



                </div>
            </div>

        </div>
    );
}

export default HalamanBeli