import react from 'react';
import { Image } from '@chakra-ui/react'
function Promotion() {


    return (

        <div className="container-fluid vw-100 pt-0"
            style={{ height: "500px" }}>
                
            <div className="row h-100 position-relative pt-0">
                <div className="col-6 ">
                    <div className="d-block h-50 d-flex align-items-end justify-content-end">
                        <div style={{
                            fontFamily: "Poppins",
                            fontSize: "32px",
                            fontWeight: "700",
                            lineHeight: "41px",
                            letterSpacing: "0px",
                            textAlign: "right",
                        }}>

                            Hubungi Agen Kami

                        </div>
                    </div>
                    <div className="d-flex justify-content-end w-100  mt-2">
                        <div className="btn-orange  text-center px-3 py-2">
                            Hubungi!
                        </div>
                    </div>
                </div>
                <div className="col-6 d-flex align-items-bottom h-100">
                    <div className="position-relative  w-100 ">

                        <Image className='position-absolute bottom-0' src='/image/Agen.png'

                        />
                    </div>
                </div>
            </div>
        </div >
    );

};

export default Promotion;