import react from 'react';

function Carousel() {


    return (
        <div className="px-4 px-md-5">
            <span style={{
                fontFamily: "Poppins",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "48px",
                letterSpacing: "0px",
                textAlign: "left",
            }}>
                Lihat Properti
            </span>
        </div>
    );
};

export default Carousel;