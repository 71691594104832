import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Homepage from './Page/Homepage';
import Properti from './Page/Properti';
import BeliProperti from './Page/BeliProperti';
import SewaProperti from './Page/SewaProperti';
import Adminpage from './Page/admin/Adminpage';
import TentangKami from './Page/TentangKami';

import 'bootstrap/dist/css/bootstrap.css'

import { ChakraProvider } from '@chakra-ui/react';

function App() {


  return (

    <div >
      <ChakraProvider>

        <Router>
          <Routes >
            <Route path='/' element={<Homepage />} />

            <Route path='/adminpanel' element={<Adminpage />} />
            <Route path='/beliproperti' element={<BeliProperti/>} />
            <Route path='/sewaproperti' element={<SewaProperti/>} />
            <Route path='/properti' element={<Properti/>} />
            <Route path='/tentangkami' element={<TentangKami/>} />
          </Routes>
        </Router>

      </ChakraProvider>
    </div>

  );
}

export default App;
