import react from 'react';
import { Image } from '@chakra-ui/react'

function Banner() {


    return (

        <div className="bg-blue h-100 vw-100 py-5 px-5">
            <div className="px-4 mx-5 px-md-5">
                <Image src='/image/pexels-lucas-vinícius-pontes-4535184.png'
                    width="100%"
                />
            </div>
            <div className="px-5 mx-5 mb-5 mt-3">
                <div className="px-5">
                    <span style={{ fontSize: "32px", zIndex: "2", color: "#FFFFFF", fontWeight: "700", lineHeight: "42.56px" }}>Agensi Real Estate yang telah berdiri
                        <br></br>
                        sejak</span><span style={{ fontSize: "32px", zIndex: "2", color: "#F26A2A", fontWeight: "700", lineHeight: "42.56px" }}> 1998 </span>
                </div>
            </div>
            <div className="px-5">
                <div className="px-5 mx-5 text-end text-light">
                    <span>Didirikan <span style={{fontWeight:"700"}}>sejak tahun 1998 </span>oleh dua orang pakar properti yang memiliki latar belakang kuat di bidang keuangan real estate, dan industri investasi, Casablanca Realty menjadi <span className="fw-bold">One-Stop Real Estate Agency</span> yang memberikan <span className="fw-bolder">pelayanan profesional</span> kepada setiap konsumennya.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                        Berfokus di <span className="fw-bold">Jakarta Pusat dan Selatan di kawasan CBD </span>seperti Kuningan, Menteng, atau Sudirman, Casablanca Realty akan terus membantu menemukan kebutuhan properti Anda, seperti: rumah, apartemen, ruang kantor, dan lainnya.
                    </span>
                    <br></br>
                    <div className="col12 my-3 d-flex justify-content-end">
                        <div className="btn-orange px-3 py-2 mt-4">
                            Selengkapnya
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Banner;