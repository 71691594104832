import react from 'react';

//component
import Header from '../Component/Header';
import Hero from '../Component/Homepage/Hero';
import Banner from '../Component/Homepage/Banner';
import Carousel from '../Component/Carousel';
import Sponsor from '../Component/Homepage/Sponsor';
import Promotion from '../Component/Promotion';
import Footerlanding from '../Component/Footerlanding';


function Homepage() {


    return (
        <div>
            <Header />
            <Hero />
            <Banner />
            <Carousel />
            <Sponsor />
            <Promotion />
            <Footerlanding />

        </div>

    );

}

export default Homepage;